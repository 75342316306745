// styles.scss or any SCSS file you are using
.profile-card {
  .ant-card-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
  }

  .profile-container{
    display: flex;
    justify-content: center;
    .profile-card{
      .profile-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      .profile-info {
        width: 100%;
        text-align: left;

        .profile-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          &.username{
            justify-content: center;
            margin-bottom: 20px;
            & .profile-label{
              font-weight: 800;
              font-size: 2rem;
            }
          }

          .profile-label {
            font-weight: bold;
            font-size: 1.1rem;
            color: #444;
          }

          .profile-value {
            font-size: 1rem;
            color: #666;
          }
        }
      }
    }
  }
}
