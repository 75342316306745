/* PdfViewer.scss */
/* pdfViewer.scss or another CSS file */

.no-decoration-link {
  text-decoration: none;
  color: white;
}

.no-decoration-link:hover,
.no-decoration-link:focus {
  text-decoration: none;
  color: white;
}
.pdf-viewer {
  margin-top: 20px;
  border: 2px black solid;
  width: 100%;
  height: 70vh;
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .rpv-core__viewer {
    width: 100%;
    height: 100%;
  }
}
