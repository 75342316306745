/* PdfViewer.scss */
.download-button {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
}
.pdf-viewer {
  border: 2px black solid;
  width: 90%;
    height: 60vh;
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .rpv-core__viewer {
    width: 100%;
    height: 100%;
  }

}
