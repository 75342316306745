.no-decoration-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: #f0f0f0;
  }
}

.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px; /* Adds separation between the two PDF viewers */
  }
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }

  .ant-btn {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 2rem;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
      background-color: #1890ff;
      border-color: #1890ff;
    }
  }
}

.pdf-viewer {
  flex: 1;
  width: 100%;
  max-width: 100%;
  border: 2px solid #ccc;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  height: 70vh; /* Ensure the viewer has a fixed height */
  
  @media (max-width: 767px) {
    height: 50vh; /* Adjust height for smaller screens */
  }

  .rpv-core__viewer {
    width: 100%;
    height: 100%;
    overflow-y: auto; /* Allow vertical scrolling */
  }

  .download-button {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 0;

    @media (max-width: 767px) {
      top: -30px;
      font-size: 0.9rem;
    }
  }
}
