.title-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.report{
    .btn-container{
      display: flex;
      align-items: center;
      gap: 20px;
      .compare-link{
        color: #000;
        padding: auto;
        font-size: 20px;
      }
    }
  }
}
.icofont-ui-calendar,
.icofont-plus-circle{
  font-size: 50px;
  transition: font-size 0.3s ease;
  &:hover{
    font-size: 55px;
  }
}
.icofont-plus-circle{
  cursor: pointer;
  color: #336cfb;
}