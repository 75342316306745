// styles.scss or any SCSS file you are using
.profile-container{
  width: 100%;
  display: flex;
  justify-content: center;
  .profile-card {
    .ant-card-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
    }

    .profile-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    .profile-info {
      padding: 0 2rem 2rem 2rem;
      width: 100%;
      text-align: left;

      .profile-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &.username{
          transform: translateX(-10px);
          justify-content: center;
          margin-bottom: 20px;
          & .profile-label{
            font-weight: 800;
            font-size: 2rem;
          }
        }

        .profile-label {
          font-weight: bold;
          font-size: 1.4rem;
          color: #444;
        }

        .profile-value {
          font-size: 1.2rem;
          color: #666;
        }
      }
    }
  }
}
