.search-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.search-input {
  margin-bottom: 20px;

  input {
    width: 100%;
    padding: 10px;

    border: 2px solid #adadad;
  }
}
