// src/pages/Unauthorized.scss
.unauthorized-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
  text-align: center;

  .unauthorized-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ff4d4f;
    margin-bottom: 20px;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
