

.btn-select{
    color: #fff !important;
    &:hover{
      background-color: rgb(79, 79, 79) !important;
    }
    &.active{
        color: #000 !important;
        background-color: #87e8de !important;
        &:hover{
            background-color: #87e8de !important;
        }
    }
  }