@import 'variables';
@import 'helpers';

#root {
  .fc {
    .fc-button {
      border-radius: $shape;
      box-shadow: $shadow;
      cursor: pointer;
      font-family: $main-ff;
      font-size: 1rem;
      font-weight: 700;
      height: auto;
      line-height: $module-rem * 2 / 1rem;
      outline: none;
      padding: calc(#{$module-rem} / 2 - 1px) ($module-rem);
      position: relative;
      text-align: center;
      text-transform: capitalize;
      touch-action: manipulation;
      transition: background 0.2s $animation, border 0.2s $animation, box-shadow 0.2s $animation,
        color 0.2s $animation, opacity 0.2s $animation;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      will-change: background, border, box-shadow, color, opacity;
      -webkit-appearance: none;

      .fc-icon {
        margin-top: -4px;
      }
    }
    .fc-button-primary {
      background: accent-palette(500);
      border-color: accent-palette(500);
      color: accent-contrast(500);
      text-shadow: none;

      &:hover {
        background: accent-palette(700);
        border-color: accent-palette(700);
        box-shadow: $shadow-hover;
        color: accent-contrast(700);
      }
      &:focus {
        box-shadow: $shadow !important;
      }
      &.fc-button-active {
        background: accent-palette(800);
        border-color: accent-palette(800);
        color: accent-contrast(800);
      }
    }
    .fc-state-default {
      background: accent-palette(500);
      border-color: accent-palette(500);
      color: accent-contrast(500);
      text-shadow: none;
    }
    .fc-button-group {
      > .fc-button {
        border-radius: 0;
      }
      > :first-child {
        border-top-left-radius: $shape;
        border-bottom-left-radius: $shape;
      }
      > :last-child {
        border-top-right-radius: $shape;
        border-bottom-right-radius: $shape;
      }
    }
    .fc-toolbar {
      .fc-state-active,
      .ui-state-active {
        background: accent-palette(800);
        border-color: accent-palette(800);
        color: accent-contrast(800);
      }
      .fc-center {
        h2 {
          font-size: 1.6rem;
        }
      }
    }
    .fc-event {
      box-shadow: $shadow;
      cursor: pointer;
      transition: box-shadow 0.2s $animation;

      &:hover {
        box-shadow: $shadow-hover;
      }
    }
    .fc-event,
    .fc-event:not([href]),
    .fc-event:not([href]):hover {
      border-color: accent-palette(500);
      color: accent-contrast(500);
    }
    .fc-event,
    .fc-event-dot {
      background-color: accent-palette(500);
    }

    .fc-event {
      .fc-event-main-frame {
        padding: 0 ($module-rem / 2);
      }

      @each $value, $color in $colors {
        &.event-#{$value} {
          background-color: $color !important;
          border-color: $color !important;
        }
      }
    }

    .fc-daygrid {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: #ebebeb;
      }

      .fc-daygrid-day.fc-day-today {
        background-color: accent-palette(50);
      }

      .fc-daygrid-day-number {
        padding: 2px !important;
      }
    }

    @media #{$max767} {
      .fc-left,
      .fc-right,
      .fc-center {
        display: flex;
        float: none;
        justify-content: center;
        margin-bottom: $module-rem;
      }
    }
  }
}
